import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Button from '../../Core/Button'
import Slider from '../../Core/Slider'
import Text from '../../Core/Text'
import * as styles from './instagram.module.scss'
import insta from '../../../images/insta.png'
import useBreakpoint from '../../../hooks/useBreakpoint'
import useObfuscateUrl from '../../../hooks/useObfuscateUrl'
import { useInView } from 'react-intersection-observer'
import { Helmet } from 'react-helmet'
import { Script } from 'gatsby'
import useGtmEvent from '../../../hooks/useGtmEvent'

interface InstagramProps {
  data?: {
    name?: string
    title?: string
    hashtag?: string
    instagramHandle?: string
    instagramLinkButton: Array<{
      url?: string
      name?: string
    }>
  },
  hidden?: boolean
}

export default function Instagram(props: InstagramProps) {
  const { data, hidden } = props

  const [inView, setInView] = useState(false)

  let hashtag = (data && data.hashtag) || '#colivingtogether'
  let title = (data && data.title) || 'On se follow'
  let instagramHandle = (data && data.instagramHandle) || '@hife.coliving'
  let instagramLinkButton = (data && data.instagramLinkButton) || []

  const breakpoint = useBreakpoint()

  const { encode, decode } = useObfuscateUrl()

  const handleGoToInsta = () => {
    useGtmEvent('clic_reseaux_sociaux', {
      reseau: 'Instagram',
      location: title,
    })
    window.open(decode(encodeUrl), 'target')
  }

  const encodeUrl =
    instagramLinkButton && instagramLinkButton[0]
      ? useMemo(() => encode(instagramLinkButton[0]?.url || ''), [])
      : ''

  const handleInView = () => {
    if (window.innerHeight >= document.body.scrollHeight) {
      setInView(true)
    } else {
      if (window.scrollY > 10 && !inView) {
        setInView(true)
      }
    }
  }

  useEffect(() => {
    handleInView()
    window.addEventListener('scroll', handleInView, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleInView)
      const script = document.querySelectorAll('script[src*="taggbox"]')
      Array.from(script).forEach((s) => s.remove())
      setInView(false)
    }
  }, [])

  useEffect(() => {
    if (inView) {
      const embeded = document.getElementById('tagbox')
      if (!embeded) {
        const script = document.createElement('script')
        script.src = 'https://widget.taggbox.com/embed-lite.min.js'
        script.id = 'tagbox'
        script.async = true

        document.body.append(script)
      }
    }
  }, [inView])

  return (
    <>
      <Helmet></Helmet>
      <section
        className={`${styles.instagram} animate ${inView ? 'in-view' : ''}`}
      >
        <div className="container">
          <div className={styles.instagramTitle}>
            <div className="">
              <Text tag="h2" as="h2" className="uppercase instagram-title">
                {title}
              </Text>
            </div>
            {!hidden && !['md', 'sm'].includes(breakpoint) && (
              <div className={styles.instagramLink}>
                <Button
                  btnType="dark"
                  className="uppercase"
                  data-o={encodeUrl}
                  onClick={handleGoToInsta}
                >
                  {title}
                </Button>
              </div>
            )}
          </div>
          <div className={styles.instagramTitle}>
            <div className="">
              <Text
                tag="p"
                data-o={encodeUrl}
                onClick={handleGoToInsta}
                as="h4"
                className="c-typo-title font-bold cursor-pointer"
              >
                {hashtag}
              </Text>
            </div>
            <div className={styles.instagramLink}>
              <Text
                tag="p"
                as="h4"
                className="c-typo-title font-bold cursor-pointer"
                data-o={encodeUrl}
                onClick={handleGoToInsta}
              >
                {instagramHandle}
              </Text>
            </div>
          </div>
          <div className={styles.instagramPost}>
            {inView && (
              <>
                <div
                  className="taggbox"
                  style={{ width: '100%', height: '100%' }}
                  data-widget-id="122799"
                  data-tags="false"
                ></div>
              </>
            )}
          </div>
          {!hidden && ['md', 'sm'].includes(breakpoint) && (
            <div className={styles.instagramMobile}>
              <Button
                btnType="dark"
                data-o={encodeUrl}
                className="uppercase"
                onClick={handleGoToInsta}
              >
                {instagramLinkButton[0]?.name}
              </Button>
            </div>
          )}
        </div>
      </section>
    </>
  )
}
