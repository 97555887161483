import React from 'react'
import { useInView } from 'react-intersection-observer'
import useBreakpoint from '../../../hooks/useBreakpoint'
import useGtmEvent from '../../../hooks/useGtmEvent'
import replaceHife from '../../../utils/replaceHife'
import KLink from '../../Core/KLink'
import Slider from '../../Core/Slider'
import Text from '../../Core/Text'
import BlockServiceSpaceItem, {
  BlockServiceSpaceItemProps,
} from './BlockServiceSpaceItem'
import * as styles from './serviceSpace.module.scss'

interface BlockServiceSpaceProps {
  data: {
    blockDisplay: string
    title: string
    subtitle: string
    spacesList: BlockServiceSpaceItemProps[]
    linkBookSpace: {
      url: string
      type: string
      name: string
    }
    linkBook: {
      url: string
      type: string
      name: string
    }
    linkBookSpaceEvent: string
  }
}

export default function BlockServiceSpace(props: BlockServiceSpaceProps) {
  const {
    data: { title, subtitle, linkBookSpace, linkBookSpaceEvent, spacesList, linkBook },
  } = props

  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  })

  const breakpoint = useBreakpoint()

  const onTrackLinkBookSpace = () => {
    if (linkBookSpaceEvent) useGtmEvent(linkBookSpaceEvent)
  }

  return (
    <section
      ref={ref}
      className={`serviceSpace ${styles.bookService} animate ${
        inView ? 'in-view' : ''
      }`}
    >
      <div className="container">
        <Text
          tag="h2"
          as="h2"
          dangerouslySetInnerHTML={{ __html: replaceHife(title) }}
        ></Text>
        <Text
          tag="p"
          as="subtitleH2"
          className="desc"
          dangerouslySetInnerHTML={{ __html: replaceHife(subtitle) }}
        ></Text>
        {linkBookSpace && (
          <KLink
            to={linkBookSpace.url}
            title=""
            label={linkBookSpace.name}
            className="mt-2"
            tracking={onTrackLinkBookSpace}
          />
        )}
        <div className={styles.bookServiceSlide}>
          <Slider
            slidesPerView={1}
            spaceBetween={32}
            autoHeight={false}
            navigation={
              ['sm'].includes(breakpoint)
                ? spacesList.length > 1
                : ['md'].includes(breakpoint)
                ? spacesList.length > 2
                : spacesList.length > 3
            }
            breakpoints={{
              768: {
                slidesPerView: 2,
                spaceBetween: 16,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 16,
              },
            }}
            data={spacesList?.map((s, index) => ({
              content: (
                <BlockServiceSpaceItem
                  content={s.content}
                  key={`bs-${index}`}
                />
              ),
            }))}
          />
        </div>
      </div>
    </section>
  )
}
