import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  withArtDirection,
} from 'gatsby-plugin-image'
import React, { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useInView } from 'react-intersection-observer'
import useBreakpoint from '../../../hooks/useBreakpoint'
import useEllipsis from '../../../hooks/useEllipsis'
import Button from '../../Core/Button'
import KLink from '../../Core/KLink'
import Slider from '../../Core/Slider'
import Text from '../../Core/Text'
import * as styles from './blockService.module.scss'
import replaceHife from '../../../utils/replaceHife'
import VideoProvider from '../../Core/VideoProvider'
import useGtmEvent from '../../../hooks/useGtmEvent'

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}

interface BlockServiceProps {
  data: {
    layout: 'Content right' | 'Content left'
    blockDisplay: string
    colorCombination: string
    contentTypeAlias: string
    title: string
    subtitle: string
    slides: Array<{
      content: {
        legend: string
        image: {
          src: string
          alt: string
          gatsbyImageData: IGatsbyImageData
        }
        imageMobile: {
          src: string
          alt: string
          gatsbyImageData: IGatsbyImageData
        }
        backgroundVimeoId: string
      }
    }>
    link: {
      url: string
      name: string
      type: string
    }
    linkEvent?: string
    button: {
      name: string
      type: string
      url: string
    }
  }
  uniqId?: string
}

export default function BlockService(props: BlockServiceProps) {
  const {
    data: {
      layout,
      slides,
      title,
      subtitle,
      button,
      link,
      linkEvent,
      colorCombination = '212345',
    },
    uniqId,
  } = props

  const sectionRef = useRef(null)
  const breakpoint = useBreakpoint()
  const ellipsis = useEllipsis()
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  })

  const onTrackLink = () => {
    if (linkEvent)
      useGtmEvent(linkEvent, {
        service: title,
      })
  }

  const onTrackButtonService = () => {
    useGtmEvent('reserver_service', {
      service: title,
    })
  }

  useEffect(() => {
    gsap.fromTo(
      `.${uniqId ? uniqId : 'block-service'} .image-animate`,
      {
        scale: 1.2,
        translateY: -80,
      },
      {
        scale: 1,
        translateY: 0,
        scrollTrigger: {
          trigger: sectionRef.current,
          start: 'top 70%',
          end: 'bottom bottom',
          scrub: true,
        },
      }
    )
  }, [])

  return (
    <section
      ref={ref}
      className={`block-service ${uniqId} ${styles.blockService} animate ${
        inView ? 'in-view' : ''
      }`}
    >
      <div
        ref={sectionRef}
        className={`${styles.blockServiceContent} ${
          layout === 'Content left' ? styles.reverse : ''
        }`}
      >
        <div>
          <div className={styles.blockServiceSlider}>
            <Slider
              loop={slides?.length > 1}
              spaceBetween={0}
              navigation={slides?.length > 1}
              animatedTransition
              speed={700}
              data={slides?.map((s, index) => {
                let slideImage =
                  s.content.image && s.content.image.gatsbyImageData
                if (
                  s.content.image &&
                  s.content.imageMobile &&
                  s.content.image.gatsbyImageData &&
                  s.content.imageMobile.gatsbyImageData
                ) {
                  if (
                    s.content.image.gatsbyImageData.layout &&
                    s.content.imageMobile.gatsbyImageData.layout
                  ) {
                    const firstImage = getImage(
                      s.content.image.gatsbyImageData
                    ) as any
                    const secondImage = getImage(
                      s.content.imageMobile.gatsbyImageData
                    ) as any

                    if (firstImage && secondImage) {
                      slideImage = withArtDirection(firstImage, [
                        {
                          media: '(max-width: 768px)',
                          image: secondImage,
                        },
                      ])
                    }
                  }
                }

                return {
                  content: s.content.backgroundVimeoId ? (
                    <VideoProvider
                      source={'vimeo'}
                      className="c-video-hero"
                      data={{
                        description: s.content.legend,
                        contentUrl: s.content.backgroundVimeoId,
                        embedUrl: s.content.backgroundVimeoId,
                        poster:
                          s.content.image && s.content.image.gatsbyImageData,
                      }}
                    />
                  ) : slideImage ? (
                    <GatsbyImage
                      key={`sl-${index}`}
                      image={slideImage}
                      alt={title}
                      className={index === 0 ? 'image-animate' : ''}
                    />
                  ) : (
                    <div className="block h-full gatsby-image-wrapper">
                      <img
                        src={s.content?.image?.src}
                        alt={''}
                        className="h-full w-full object-cover"
                      />
                    </div>
                  ),
                }
              })}
            />
          </div>
        </div>
        <div className="lg:flex justify-end">
          <div
            className={styles.blockServiceWrap}
            style={
              { '--inner-bg': `#${colorCombination}` } as React.CSSProperties
            }
          >
            <div className={styles.blockServiceBg} />
            <div className={styles.blockServiceInfo}>
              <Text
                tag="h2"
                className="c-typo-title title"
                dangerouslySetInnerHTML={{ __html: replaceHife(title) }}
              ></Text>
              <div>
                <Text
                  tag="p"
                  as="bodyH1"
                  dangerouslySetInnerHTML={{
                    __html: replaceHife(
                      ['sm', 'md'].includes(breakpoint)
                        ? ellipsis(subtitle, 146)
                        : subtitle
                    ),
                  }}
                ></Text>
                <div className="lg:flex gap-x-9 items-center">
                  {button && (
                    <div className="lg:mb-0 mb-6">
                      <Button
                        to={button.url}
                        btnType="light"
                        tracking={onTrackButtonService}
                      >
                        {button.name}
                      </Button>
                    </div>
                  )}
                  {link && (
                    <KLink
                      to={link.url}
                      title=""
                      className="text-white inline-flex"
                      label={link.name}
                      tracking={onTrackLink}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
