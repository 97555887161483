import React from 'react'
import { useInView } from 'react-intersection-observer'
import useBreakpoint from '../../../hooks/useBreakpoint'
import Accordion from '../../Core/Accordion'
import KLink from '../../Core/KLink'
import Text from '../../Core/Text'
import * as styles from './questionnaire.module.scss'
import Button from '../../Core/Button'
import { useTranslation } from '../../../hooks/useTranslation'

interface QuestionnairesProps {
  data: {
    contentTypeAlias: string
    title: string
    illustration: {
      src: string
    }
    linkFaq: {
      name: string
      url: string
    }
    itemsFaq: Array<{
      question: string
      answer: string
    }>
    ctaTitle?: string
    ctaAction?: string
  }
  layout?: string
  residence?: any
}

export default function Questionnaires(props: QuestionnairesProps) {
  const { data, layout = 'default',  residence } = props
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  })

  const breakpoint = useBreakpoint()
  const { t } = useTranslation('residence')

  const illustration = (
    <img
      src={data.illustration.src}
      className="illustration"
      alt=""
      loading="lazy"
    />
  )

  const itemsFaq =
    data.itemsFaq && data.itemsFaq.filter((item) => item && item.question)


  const handleGoToInfo = () => {
    if(data.ctaAction && data.ctaAction == 'encre formulaire'){
      const $info = document.getElementById('landing-form')
      window.scrollTo({
        top: $info?.offsetTop,
        behavior: 'smooth',
      })
    } else {
      window.location.href = `https://reserver.hife-coliving.fr/#/tunnel?id=${residence._id}`;
    }
  }

  return (
    <section
      ref={ref}
      id={data.contentTypeAlias}
      className={`block-faq ${styles.questionnaire} animate ${
        inView ? 'in-view' : ''
      }`}
    >
      <div className={`container ${styles.questionnaireWrap}`}>
        <div className={styles.questionaireTitle}>
          <Text tag="h2" as="h2" className="questionnaires-title">
            {data.title}
          </Text>
          {data.linkFaq && layout !== 'landing' && (
            <KLink
              to={data.linkFaq.url}
              label={data.linkFaq.name}
              btnType="dark"
            />
          )}
          {!['sm', 'md'].includes(breakpoint) && illustration}
        </div>
        <div className={`${styles.questionnaireItem} flex flex-col`}>
          {itemsFaq && (
            <div className={'mb-8'}>
              <Accordion
                data={itemsFaq.map((faq) => ({
                  title: (
                    <Text tag="div" as="h7" className="font-normal text-black">
                      {faq?.question}
                    </Text>
                  ),
                  content: faq?.answer,
                }))}
              />
              {['md'].includes(breakpoint) && illustration}
            </div>
          )}
          {layout === 'landing' && (
            <Button
              // type="EXTERNAL"
              btnType="light"
              tag={'a'}
              // url="https://reserver.hife-coliving.fr/"
              target="_blank"
              className="mr-auto mt-auto mb-0 ml-0"
              onClick={handleGoToInfo}
            >
              { data.ctaTitle && data.ctaTitle !== '' ? data.ctaTitle : t('Réserver', 'residence') as string}
            </Button>
          )}
        </div>
      </div>
    </section>
  )
}
